import { compact, isNil, max, min, orderBy, range, toInteger } from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'
import { CHRONICLES_CONSTANTS, ENTRY_SETTINGS_FREQUENCY, POINT_STATUS_DECLARATION, RESTRICTION_RESOURCE_TYPE, SAMPLE_TYPES } from '../pages/online/agri/constants/AgriConstants'
import moment from 'moment'
import { CheckCircleOutlined, Error, PlayArrow, Square } from '@mui/icons-material'
import AppStore from '../store/AppStore'
import { Tooltip } from '@mui/material'

const getRestrictionIconV2 = (level, sx) => {
    if (level && level !== 1) {
        return <Tooltip title={i18n.restrictionLevel}><Error sx={sx} /></Tooltip>
    }
    return <Tooltip title={i18n.restrictionLevel}><CheckCircleOutlined sx={{ color: 'green', ...sx }} /></Tooltip>
}

const formatValueDay = (waterTurn, day, plageActuelle) => {
    return waterTurn[day].split('').map((v, i) => {
        if (i === plageActuelle) {
            return `${v}|`
        }
        return v
    }).join('')
}

const getWaterTurns = (waterTurns, restrictions, waterTurnsSlots) => {
    const allRestrictions = [
        ...restrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
        },
    ]
    const date = new Date()
    const startHour = 2
    const day = `day${date.getDay() || 7}`
    const hour = Math.abs(date.getHours() - startHour)
    const allLevels = allRestrictions.map((r) => r.level)
    const restrictionMax = max(allLevels)
    const allActualLevels = waterTurns.map((w) => {
        const slot = waterTurnsSlots.find(wts => wts.id === w.idSlot) || { nbSlots: 1, id: -1 }
        const slotsDisplay = range(slot.nbSlots || 1)
        const dureePlage = 24 / slot.nbSlots
        const plageActuelle = Math.trunc(hour / dureePlage)
        const allDays = (() => {
            let week = (day !== 'day1') ? w.day1 : formatValueDay(w, day, plageActuelle)
            week = (day !== 'day2') ? (week + w.day2) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day3') ? (week + w.day3) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day4') ? (week + w.day4) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day5') ? (week + w.day5) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day6') ? (week + w.day6) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day7') ? (week + w.day7) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            return week
        })()
        const allDaysSplit = allDays.split('|')
        const allDaysFromNow = allDaysSplit[1] + allDaysSplit[0]
        let nextIndex = null
        allLevels.forEach((l) => {
            if (l !== restrictionMax) {
                const next = allDaysFromNow.indexOf(l)
                if (next !== -1 && (isNil(nextIndex) || next < nextIndex)) {
                    nextIndex = next
                }
            }
        })
        const value = w[day][slotsDisplay[plageActuelle]] || 'X'
        return { ...allRestrictions.find((r) => `${r.level}` === value), slot, timeNextSampling: nextIndex === -1 ? 0 : (nextIndex * dureePlage) }
    })
    const x = allActualLevels.filter((r) => r.level === 'x')
    const others = orderBy(allActualLevels.filter((r) => r.level !== 'x'), 'level')
    return [...x, ...others][0]
}

const getColorAndLabelWaterTurn = (waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point) => {
    const waterTurns = exploitationWaterTurns.filter((w) => w.idInstallation === point.id && w.year === moment().year())
    if (waterTurns) {
        const hour = new Date().getHours()
        const res = getWaterTurns(waterTurns, waterTurnsRestrictions, waterTurnsSlots)
        if (res) {
            const allLevels = waterTurnsRestrictions.map((r) => r.level)
            const restrictionMax = max(allLevels)
            const { slot } = res
            const { label, time } = compact((slot.slots || '').split('/').map((s) => {
                const [start, end] = s.split('-')
                const timeRemaining = end - hour
                if ((hour >= parseInt(start) && hour < parseInt(end)) || start === end) {
                    return { label: `${start}h-${end}h${min(allLevels) === res.level ? '' : '*'}`, time: timeRemaining < 0 ? (timeRemaining + 24) : timeRemaining }
                }
                return null
            }))[0] || ''
            return { color: restrictionMax === res.level ? 'red' : res.color, label, max: restrictionMax === res.level, timeNextSampling: res.timeNextSampling + time }
        }
        return { color: 'grey', label: i18n.noWaterTurn }
    }
    return { color: 'grey', label: i18n.noWaterTurn }
}

const getEvolValue = (type, chronicles, chronicle, index) => {
    if (type === CHRONICLES_CONSTANTS.TYPE_INDEX) {
        return chronicle.value - chronicles[index + 1].value
    }
    return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
}

const getIPSLabel = (level) => {
    switch (level) {
        case 'up':
            return i18n.improvement
        case 'right':
            return i18n.stagnation
        case 'down':
            return i18n.degradation
        default:
            return i18n.unknown
    }
}

const getIPSIconDesktop = (level, color) => {
    switch (level) {
        case 'up':
            return <PlayArrow sx={{ color, rotate: '-90deg', fontSize: '25px' }} />
        case 'right':
            return <Square sx={{ color, fontSize: '15px' }} />
        case 'down':
            return <PlayArrow sx={{ color, rotate: '90deg', fontSize: '25px' }} />
        default:
            return undefined
    }
}

const getStateLabel = (stateCode) => {
    switch (stateCode) {
        case 1:
            return i18n.usedLabel
        case 2: default:
            return i18n.notUsedLabel
        case 3:
            return i18n.closed
    }
}

const getDelaySinceLastEntry = (entryDate, point) => {
    const {
        managementUnitsRestrictions,
        waterTurnsRestrictions,
        droughtSectorsRestrictions,
        applicationSettings,
    } = {
        managementUnitsRestrictions: AppStore.getState().ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: AppStore.getState().ReferencialReducer.waterTurnsRestrictions,
        droughtSectorsRestrictions: AppStore.getState().ReferencialReducer.droughtSectorsRestrictions,
        applicationSettings: AppStore.getState().HomeReducer.applicationSettings
    }
    const appSettingEntryFrequency = toInteger(applicationSettings?.find(s => s.parameter === 'entryFrequency'))
    const appSettingEntryFrequencyDay = toInteger(applicationSettings?.find(s => s.parameter === 'entryFrequencyDay'))
    const typeRestriction = applicationSettings?.find(s => s.parameter === 'agriTypeRestriction') ?? RESTRICTION_RESOURCE_TYPE.MANAGEMENT_UNITS

    const restriction = typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS ?
        droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
        :
        managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
    const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
    const entryFrequency = waterTurnsRestriction?.entryFrequency ?? appSettingEntryFrequency ?? undefined
    const entryFrequencyDay = waterTurnsRestriction?.entryFrequencyDay ?? appSettingEntryFrequencyDay ?? undefined
    let warnDate = entryDate
    if (!entryFrequency && !entryFrequencyDay) {
        return { warnDate, warning: false }
    }
    if (entryFrequency === ENTRY_SETTINGS_FREQUENCY.WEEKLY) {
        warnDate = moment(moment(entryDate).day(7 + entryFrequencyDay))
    } else {
        warnDate = moment(moment(entryDate).date(moment(entryDate).daysInMonth() + entryFrequencyDay))
    }
    if (moment().diff(warnDate, 'days') > 0) {
        return { warnDate, warning: true }
    }
    return { warnDate: entryDate, warning: false }
}

const getStateLabelDecla = (stateCode) => {
    switch (stateCode) {
        case POINT_STATUS_DECLARATION.USED:
            return i18n.usedLabel
        case POINT_STATUS_DECLARATION.REMOVED :
            return i18n.closed
        default:
            return i18n.notUsedLabel
    }
}

const getTypePrel = (key) => {
    switch (key) {
        case SAMPLE_TYPES.UNDERGROUND:
            return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
        case SAMPLE_TYPES.SUPERFICIAL:
            return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
        default:
            return 'PREL_AGRI.NATURE_PRELEVEMENT'
    }
}


export { getRestrictionIconV2, getWaterTurns, getEvolValue, getColorAndLabelWaterTurn, getIPSLabel, getIPSIconDesktop,
    getStateLabel, getDelaySinceLastEntry, getStateLabelDecla, getTypePrel }

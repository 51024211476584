import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid2 } from '@mui/material'
import { isNil, orderBy, template } from 'lodash'
import moment from 'moment'
import { Cancel, Error, KeyboardArrowLeft } from '@mui/icons-material'
import { ReactComponent as PumpLogo } from '../../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../../ressources/static/svg/Counter.svg'
import { convertToRGB } from '../../../../utils/ColorUtil'
import { formatDate } from '../../../../utils/DateUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { formatMilliers, getSandreLabel } from '../../../../utils/StringUtil'
import useApplicationSetting from '../../../../utils/customHooks/useApplicationSetting'
import { CHRONICLES_CONSTANTS } from '../../agri/constants/AgriConstants'
import InstallationsAction from '../../referencials/installations/actions/InstallationsAction'
import ModalIndexEntry from '../../components/ModalIndexEntry'
import LoadingCard from '../../components/cards/LoadingCard'
import { RedButton } from '../../components/styled/buttons'
import { LightCard } from '../../components/styled/grid'
import { darkRed, darkTextColor, otherBlue, textColor } from '../../components/styled/theme'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../../referencials/materiels/constants/MaterielConstants'
import { useParams } from 'react-router'

const IndexMaterielsAppDecla = () => {
    const [pointsLoaded, setPointsLoaded] = useState(false)
    const [openModalNewIndex, setOpenModalNewIndex] = useState(false)
    const [indexedMaterial, setIndexedMaterial] = useState({})

    const params = useParams()

    const dispatch = useDispatch()

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', (setting) => setting ? setting.split(',').map((id) => Number(id)) : [])

    const idInstallation = useMemo(() => Number(params.id), [params])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        variousMatTypes,
        codesSandre,
        declaration,
    } = useSelector(store => ({
        exploitation: store.ExploitationReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.MaterielReducer.variousMateriels,
        variousMatSituations: store.MaterielReducer.variousMatSituations,
        variousMatTypes: store.MaterielReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        declaration: store.DeclarationReducer.declaration
    }), shallowEqual)

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!installations.length) {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                    setPointsLoaded(true)
                })
            } else {
                setPointsLoaded(true)
            }
        }
    }, [exploitation, installations])


    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])

    const { pumpsIds, countersIds, materiels } = useMemo(() => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const idsPumps = pointPumps.map(({ idVarious }) => idVarious)
        const idsCounters = pointCounters.map(({ idVarious }) => idVarious)
        const mats = variousMateriels.filter((m) => [...idsPumps, ...idsCounters].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        const updatedMats = [ ...declaration.link_pumps, ...declaration.link_counters ].filter(m => [...idsPumps, ...idsCounters].includes(m.id) && m.idInstallation === installation.id)
        return {
            pumpsIds: idsPumps,
            countersIds: idsCounters,
            materiels: mats.map(m => {
                const mat = updatedMats.find(um => um.id === m.id)
                if (mat) {
                    return {
                        ...m,
                        ...m.counter,
                        ...m.pump,
                        ...mat
                    }
                }
                return {
                    ...m,
                    ...m.pump,
                    ...m.counter,
                }
            }),
        }
    }, [idInstallation, variousMateriels, variousMatSituations])

    const lastIndexSince = useMemo(() => {
        const lastChronicle = orderBy(exploitation?.link_chronicles?.filter((c) => [...pumpsIds, ...countersIds].includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX) || [], 'measureDate', 'desc')[0]
        if (lastChronicle) {
            return moment().diff(moment(lastChronicle.measureDate), 'days')
        }
        return 0
    }, [pumpsIds, countersIds, idInstallation])


    const lastChronicle = useMemo(() => {
        const pointChronicles = exploitation?.link_chronicles?.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!installation.startDate || measureDate >= installation.startDate) &&
            (!installation.endDate || measureDate < installation.endDate)
        ) || []
        const last = pointChronicles.length ? pointChronicles.reduce((acc, chronique) => (acc.measureDate > chronique.measureDate) ? acc : chronique) : null
        if (last && last.measureType !== CHRONICLES_CONSTANTS.TYPE_ESTIM) {
            return (
                <Grid2
                    sx={{
                        border: `1px solid ${otherBlue}`,
                        borderRadius: '8px',
                        padding: '6px 12px',
                        fontSize: '12px',
                        color: otherBlue,
                        width: 'fit-content',
                        marginBottom: '16px',
                    }}
                >
                    {`${i18n.lastIndexEntryAt}${formatDate(last.measureDate)} : ${formatMilliers(last.value) || 0}`}
                </Grid2>
            )
        }
        return null
    }, [])

    const materials = useMemo(() => {
        return orderBy(materiels, ['counter', 'pump', 'reference']).map((m) => {
            const matType = variousMatTypes.find((t) => t.materielType === m.materielType)
            const isPump = !isNil(m?.pump)
            const matCategory = getSandreLabel(codesSandre, 'MAT.MOBILITE', m.mobilityCode)
            return (
                <Grid2
                    container
                    alignItems='stretch'
                    size={3}
                >
                    <LightCard
                        key={m.id}
                        container
                        size={12}
                        direction='column'
                        className='clickable'
                        sx={{ height: 'auto' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIndexedMaterial(m)
                            setOpenModalNewIndex(true)
                        }}
                    >
                        <Grid2 container justifyContent='space-between' sx={{ fontSize: '24px', fontWeight: 'medium' }}>
                            <Grid2>
                                {m.counter ? (
                                    <>
                                        <CounterLogo style={{ marginRight: '10px' }} />
                                        {i18n.counter}
                                    </>
                                ) : (
                                    <>
                                        <PumpLogo style={{ marginRight: '10px' }} />
                                        {i18n.pump}
                                    </>
                                )}
                            </Grid2>
                            {m?.reference ?? ''}
                        </Grid2>
                        {m?.name && <Grid2 className='bold' sx={{ marginTop: '8px', fontSize: '14px' }}>{m.name}</Grid2>}
                        <Grid2 container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                            {isPump ? i18n.nature : i18n.type}
                            <b>{matType ? matType.name : '-'}</b>
                        </Grid2>
                        {isPump ? (
                            <>
                                <Grid2 container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                    {i18n.categ}
                                    <b>{matCategory ?? '-'}</b>
                                </Grid2>
                                <Grid2 container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                    {i18n.debitFonctionnement}
                                    <b>{m?.operatingFlow ?? '-'}</b>
                                </Grid2>
                            </>
                        ) : (
                            <Grid2 container fontSize={14} justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                {i18n.mobility}
                                <b>{matCategory?.name ?? '-'}</b>
                            </Grid2>
                        )}
                    </LightCard>
                </Grid2>
            )
        })
    }, [materiels, variousMatTypes, codesSandre])

    if (!pointsLoaded) {
        return <LoadingCard />
    }

    if (materials.length) {
        return (
            <Grid2
                container
                direction='column'
                size={12}
                sx={{
                    maxHeight: 'calc(100% - 4vh)',
                }}
            >
                <Grid2 container sx={{ paddingBottom: '2.5vh' }} justifyContent='flex-start' color={textColor}>
                    <Grid2
                        sx={{ paddingRight: '1.5vw' }}
                        className='clickable'
                        onClick={() => window.history.back()}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid2>
                    <Grid2 sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.selectConcernedMaterial}</Grid2>
                </Grid2>
                {lastIndexSince >= (365 * 3) ? (
                    <RedButton
                        startIcon={<Error />}
                        noFullWidth={true}
                        sx={{
                            minHeight: '32px',
                            marginTop: 0,
                            fontSize: '12px',
                            marginBottom: '16px',
                            color: darkRed,
                            backgroundColor: `rgba(${convertToRGB(darkRed)}, 0.1)`,
                            border: `1px solid ${darkRed}`,
                            borderRadius: '8px',
                            '&:disabled': {
                                color: darkRed,
                                backgroundColor: `rgba(${convertToRGB(darkRed)}, 0.1)`,
                                border: `1px solid ${darkRed}`,
                                borderRadius: '8px',
                            },
                        }}
                        disabled
                    >
                        {template(i18n.noIndexSinceXDaysShort)({ days: lastIndexSince })}
                    </RedButton>
                ) : lastChronicle}
                <Grid2 container spacing={2} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                    {materials}
                </Grid2>
                {openModalNewIndex && <ModalIndexEntry
                    point={{ ...installation, consoType: materielsTypeForIndex.includes(indexedMaterial.materielType) ? CHRONICLES_CONSTANTS.TYPE_INDEX : CHRONICLES_CONSTANTS.TYPE_ESTIM }}
                    openModalNewIndex={openModalNewIndex}
                    setOpenModalNewIndex={setOpenModalNewIndex}
                    material={indexedMaterial}
                />}
            </Grid2>
        )
    }
    return (
        <Grid2 container size={12} sx={{ height: 'calc(100% - 2.5vh - 28px)', alignContent: 'center' }}>
            <Grid2 container size={12} sx={{ color: darkTextColor, paddingBottom: '12px', justifyContent: 'center' }}><Cancel sx={{ fontSize: '70px' }}/></Grid2>
            <Grid2 container size={12} sx={{ fontSize: '24px', color: darkTextColor, justifyContent: 'center' }} >{i18n.noMaterials}</Grid2>
        </Grid2>
    )
}

export default IndexMaterielsAppDecla

import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid2 } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { hasValue } from '../../../utils/NumberUtil'
import { isNil, orderBy } from 'lodash'
import { LightCard } from '../components/styled/grid'
import { ReactComponent as PumpLogo } from '../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../ressources/static/svg/Counter.svg'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import { Cancel, KeyboardArrowLeft } from '@mui/icons-material'
import { darkTextColor, textColor } from '../components/styled/theme'
import ModalEventEntry from '../components/ModalEventEntry'
import { useParams } from 'react-router'
import { arrayOf, instanceOf } from '../../../utils/StoreUtils'
import DtoVariousMatSituation from '../referencials/materiels/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/materiels/dto/DtoVariousMateriel'

const EventsMaterielsApp = () => {
    const [openModalNewEvent, setOpenModalNewEvent] = useState(false)
    const [selectedMaterial, setSelectedMaterial] = useState({})

    const params = useParams()

    const idInstallation = useMemo(() => Number(params.id), [params])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        variousMatTypes,
        codesSandre,
    } = useSelector(store => ({
        exploitation: store.ExploitationReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.MaterielReducer.variousMateriels,
        variousMatSituations: store.MaterielReducer.variousMatSituations,
        variousMatTypes: store.MaterielReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [installation, idInstallation])

    const { materiels } = useMemo(() => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const idsPumps = pointPumps.map(({ idVarious }) => idVarious)
        const idsCounters = pointCounters.map(({ idVarious }) => idVarious)
        return {
            materiels: variousMateriels.filter((m) => [...idsPumps, ...idsCounters].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)),
        }
    }, [idInstallation, variousMateriels, variousMatSituations])

    const getMaterials = () => (
        orderBy(materiels, ['counter', 'pump', 'reference']).map((m) => {
            const matType = variousMatTypes.find((t) => t.materielType === m.materielType)
            const isPump = !isNil(m?.pump)
            const matCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === m.mobilityCode)
            return (
                <Grid2
                    container
                    size={3}
                >
                    <LightCard
                        key={m.id}
                        container
                        size={12}
                        className='clickable'
                        alignContent={'flex-start'}
                        spacing={0.5}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenModalNewEvent(true)
                            setSelectedMaterial(m)
                        }}
                    >
                        <Grid2 container size={12} spacing={0} justifyContent='space-between' sx={{ fontSize: '24px', fontWeight: 'medium' }}>
                            <Grid2 >
                                {m.counter ? (
                                    <>
                                        <CounterLogo style={{ marginRight: '10px' }} />
                                        {i18n.counter}
                                    </>
                                ) : (
                                    <>
                                        <PumpLogo style={{ marginRight: '10px' }} />
                                        {i18n.pump}
                                    </>
                                )}
                            </Grid2>
                            <Grid2 >{m?.reference ?? ''}</Grid2>
                        </Grid2>
                        {m?.name &&
                            <Grid2 container size={12} justifyContent='space-between' sx={{ fontSize: '14px' }}>
                                <Grid2 >{i18n.name}</Grid2>
                                <Grid2 className='bold'>{m?.name}</Grid2>
                            </Grid2>
                        }
                        <Grid2 container size={12} justifyContent='space-between' sx={{ fontSize: '14px' }}>
                            <Grid2 >{isPump ? i18n.nature : i18n.type}</Grid2>
                            <Grid2 className='bold'>{matType ? matType.name : '-'}</Grid2>
                        </Grid2>
                        {isPump ? (
                            <>
                                <Grid2 container size={12} justifyContent='space-between' sx={{ fontSize: '14px' }}>
                                    <Grid2 >{i18n.categ}</Grid2>
                                    <Grid2 className='bold'>{matCategory?.name ?? '-'}</Grid2>
                                </Grid2>
                                <Grid2 container size={12} justifyContent='space-between' sx={{ fontSize: '14px' }}>
                                    <Grid2 >{i18n.debitFonctionnement}</Grid2>
                                    <Grid2 className='bold'>{m?.pump?.operatingFlow ?? '-'}</Grid2>
                                </Grid2>
                            </>
                        ) : (
                            <>
                                <Grid2 container size={12} justifyContent='space-between' sx={{ fontSize: '14px' }}>
                                    <Grid2 >{i18n.mobility}</Grid2>
                                    <Grid2 className='bold'>{matCategory?.name ?? '-'}</Grid2>
                                </Grid2>
                            </>
                        )}
                    </LightCard>
                </Grid2>
            )
        }
        )
    )

    if (materiels.length) {
        return (
            <Grid2
                container
                size={12}
                spacing={3}
                alignContent={'flex-start'}
                sx={{
                    maxHeight: 'calc(100% - 4vh)',
                }}
            >
                <Grid2 container size={12} spacing={3} color={textColor}>
                    <Grid2
                        className='clickable'
                        onClick={() => window.history.back()}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid2>
                    <Grid2 sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.selectConcernedMaterial}</Grid2>
                </Grid2>
                <Grid2 container size={12} spacing={2} >
                    {getMaterials()}
                </Grid2>
                {openModalNewEvent && <ModalEventEntry
                    installation={installation}
                    selectedMaterial={selectedMaterial}
                    openModalNewEvent={openModalNewEvent}
                    setOpenModalNewEvent={setOpenModalNewEvent}
                    setSelectedMaterial={setSelectedMaterial}
                />}
            </Grid2>
        )
    }
    return (
        <Grid2 container size={12} sx={{ height: 'calc(100% - 2.5vh - 28px)', alignContent: 'center' }}>
            <Grid2 container size={12} sx={{ color: darkTextColor, paddingBottom: '12px', justifyContent: 'center' }}><Cancel sx={{ fontSize: '70px' }}/></Grid2>
            <Grid2 container size={12} sx={{ fontSize: '24px', color: darkTextColor, justifyContent: 'center' }} >{i18n.noMaterials}</Grid2>
        </Grid2>
    )
}

EventsMaterielsApp.propTypes = {
    exploitation: instanceOf(DtoExploitation),
    installations: arrayOf(DtoInstallation),
    codesSandre: arrayOf(DtoSandreCode),
    variousMateriels: arrayOf(DtoVariousMateriel),
    variousMatSituations: arrayOf(DtoVariousMatSituation),
}

export default EventsMaterielsApp

/* eslint-disable camelcase */
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import React, { useEffect, useMemo, useState } from 'react'
import { grey, mainColor, ongoingWhite, textColor, toDoOrange, validateGreen, veryLightGrey } from '../../../components/styled/theme'
import { MainButton, TextButton } from '../../../components/styled/buttons'
import Table from '../../../components/Table'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getStateLabelDecla } from '../../../../../utils/AgriUtils'
import { SITU_POINT_PUMP } from '../../../referencials/materiels/constants/MaterielConstants'
import { push } from 'connected-react-router'
import { compact, orderBy } from 'lodash'
import { DECLA_LAST_STEP, DECLA_POINTS_ADVANCEMENT, DECLARATION_STATUS, POINT_ADVANCEMENT_DECLARATION, POINT_STATUS_DECLARATION, SURVEY_TYPE } from '../../../agri/constants/AgriConstants'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../../../components/styled/dialog'
import { Add } from '@mui/icons-material'
import { getI18nTitleData } from '../../../../../utils/StringUtil'
import PropTypes from 'prop-types'
import useLocalStorage from '../../../../../utils/customHooks/useLocalStorage'
import DeclarationAction from '../../actions/DeclarationAction'

const PointsValidation = ({ setValidationStep }) => {
    const {
        exploitation,
        citiesIndex,
        installations,
        declaration,
        survey,
        variousMateriels,
    } = useSelector((store) => ({
        exploitation: store.ExploitationReducer.exploitation,
        citiesIndex: store.CityReducer.citiesIndex,
        installations: store.InstallationsReducer.installations,
        declaration: store.DeclarationReducer.declaration,
        survey: store.DeclarationReducer.survey,
        variousMateriels: store.MaterielReducer.variousMateriels
    }))

    const [pointsAdvancement, setPointsAdvancement] = useLocalStorage(DECLA_POINTS_ADVANCEMENT, exploitation.link_samplings.map((linkPoint) => {
        return linkPoint && {
            idDeclaration: declaration.idDeclaration,
            idPoint: linkPoint.idInstallation,
            status: POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
        }
    }))
    const filteredPointsAdvancement = useMemo(() => pointsAdvancement?.filter(a => a.idDeclaration === declaration.idDeclaration) || [], [pointsAdvancement, declaration])
    const date = moment().valueOf()
    const [editMode, setEditMode] = useState(declaration.lastStep === DECLA_LAST_STEP.POINTS_STEP)
    const [openModal, setOpenModal] = useState(false)

    const getPumpIds = (idInstallation) => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (!m.situationEndDate || m.situationEndDate > date) && (!m.situationDate || m.situationDate < date)) || []
        return pointPumps.map(({ idVarious }) => idVarious)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if (!filteredPointsAdvancement.length && exploitation.idExploitation && declaration.idDeclaration) {
            if (!pointsAdvancement) {
                setPointsAdvancement((exploitation?.link_samplings?.map((linkPoint) => (
                    {
                        idDeclaration: declaration.idDeclaration,
                        idPoint: linkPoint.idInstallation,
                        status: POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
                    }
                ))))
            } else {
                setPointsAdvancement([
                    ...pointsAdvancement,
                    ...exploitation?.link_samplings?.filter((linkPoint) =>
                        !pointsAdvancement.some((point) => point.idPoint === linkPoint.idInstallation
                                    && point.idDeclaration === declaration.idDeclaration)
                    ).map((linkPoint) => ({
                        idDeclaration: declaration.idDeclaration,
                        idPoint: linkPoint.idInstallation,
                        status: POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
                    }))
                ])
            }
        }
    }, [exploitation, declaration])

    const points = useMemo(() => {
        const exploitPoints = compact(exploitation.link_samplings.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point?.townCode] || {}
                const advancement = filteredPointsAdvancement.find(a => a.idDeclaration === declaration.idDeclaration && a.idPoint === point.id)?.status
                return {
                    ...point,
                    cityName: city?.name,
                    stateCode: declaration.link_declarationInstallation.find(instal => point.id === instal.idInstallation)?.stateCode,
                    status: advancement,
                }
            }
            return null
        }))
        return orderBy(exploitPoints, 'status')
    }, [exploitation, filteredPointsAdvancement])

    const getCurrentUsages = (point) => (
        declaration.link_declarationInstallation.find(instal => point.id === instal.idInstallation)?.link_usagesCurrent?.length ?? 0
    )

    const getCurrentConso = (point) => {
        let total = 0
        let lowWaterVolume = 0
        declaration.link_declarationInstallation.forEach(instal => {
            if (instal.idInstallation === point.id) {
                instal.link_usagesCurrent.forEach(usage => {
                    if (usage.idInstallation === point.id) {
                        if (usage.requestedYearVolume) {
                            total = total + (usage?.requestedYearVolume ?? 0)
                            lowWaterVolume = lowWaterVolume + (usage?.lowWaterVolume ?? 0)
                        }
                    }
                })
            }
        })
        return { total, lowWaterVolume }
    }

    const getRequestUsages = (point) => (
        declaration.link_declarationInstallation.find(instal => point.id === instal.idInstallation)?.link_usages?.length ?? 0
    )

    const getRequestConso = (point) => {
        let total = 0
        let lowWaterVolume = 0
        declaration.link_declarationInstallation.forEach(instal => {
            if (instal.idInstallation === point.id) {
                instal.link_usages.forEach(usage => {
                    if (usage.idInstallation === point.id) {
                        total = total + (usage?.requestedYearVolume ?? 0)
                        lowWaterVolume = lowWaterVolume + (usage?.lowWaterVolume ?? 0)
                    }
                })
            }
        })
        return { total, lowWaterVolume }
    }

    const AdvancementIndicator = (advancement) => {
        switch (advancement) {
            default : case POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE :
                return {
                    sx: { backgroundColor: toDoOrange },
                    content: i18n.toCompleteWithMaj
                }
            case POINT_ADVANCEMENT_DECLARATION.ONGOING :
                return {
                    sx: { backgroundColor: ongoingWhite },
                    content: i18n.ongoingWithMaj
                }
            case POINT_ADVANCEMENT_DECLARATION.VALIDATED :
                return {
                    sx: { backgroundColor: validateGreen },
                    content: i18n.validatedWithMaj
                }
        }
    }

    const getAdvancementBox = (point) => {
        const { sx, content } = AdvancementIndicator(point.status)
        return (
            <Grid2
                container
                alignItems='center'
                justifyContent='center'
                sx={{
                    borderRadius: '8px',
                    border: `solid 1px ${veryLightGrey}`,
                    padding: '1vh 1vw',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    color: 'black',
                    textWrap: 'nowrap',
                    fontWeight: 500,
                    ...sx
                }}
            >
                {content}
            </Grid2>

        )
    }

    const getRows = () => {
        return (
            points.map(p => {
                const pumpIds = getPumpIds(p.id)
                return (
                    {
                        [i18n.pointNameID]: { value: p.name ? <>{p.name}<br/>{p.code}</> : p.code },
                        [i18n.status]: { value: getStateLabelDecla(p.stateCode) },
                        [i18n.pumps]: { value: `${pumpIds?.length ?? 0} ${getI18nTitleData(i18n.pump, i18n.pumps, pumpIds)}` },
                        [`${i18n.usesNumber} ${i18n.currentUsages}`]: { value: getCurrentUsages(p) },
                        [`${i18n.m3} ${i18n.currentUsages}`]: { value: getCurrentConso(p).total },
                        [`${i18n.usesNumber} ${i18n.askedsLabel}`]: { value: getRequestUsages(p) },
                        [`${i18n.m3} ${i18n.askedsLabel}`]: { value: getRequestConso(p).total },
                        [i18n.advancement]: { value: declaration.lastStep > DECLA_LAST_STEP.POINTS_STEP ? '' : getAdvancementBox(p) },
                        onClickValue: p,
                        body: [{
                            [i18n.pointNameID]: { value: '' },
                            [i18n.status]: { value: '' },
                            [i18n.pumps]: { value: (
                                <Grid2 container size={12}>
                                    {
                                        (pumpIds?.map(id => {
                                            const mat = variousMateriels.find(m => m.id === id)
                                            return (
                                                <Grid2 container size={12}>
                                                    <Grid2 container sx={{ color: grey }}>{mat?.name}</Grid2>
                                                    <Grid2 container sx={{ color: grey }}>{mat?.reference}</Grid2>
                                                </Grid2>
                                            )
                                        }))
                                    }
                                </Grid2>
                            ) },
                            [`${i18n.usesNumber} ${i18n.currentUsages}`]: { value: '' },
                            [`${i18n.m3} ${i18n.currentUsages}`]: { value: (
                                <Grid2 container size={12}>
                                    <Grid2 container size={12}>{i18n.lowWaterVolumeLabel}</Grid2>
                                    <Grid2 container sx={{ color: grey }}>{getCurrentConso(p).lowWaterVolume}</Grid2>
                                    <Grid2 container size={12}>{i18n.horsEtiage}</Grid2>
                                    <Grid2 container sx={{ color: grey }}>{getCurrentConso(p).total - getCurrentConso(p).lowWaterVolume}</Grid2>
                                </Grid2>
                            ) },
                            [`${i18n.usesNumber} ${i18n.askedsLabel}`]: { value: '' },
                            [`${i18n.m3} ${i18n.askedsLabel}`]: { value: (
                                <Grid2 container>
                                    <Grid2 container size={12}>{i18n.lowWaterVolumeLabel}</Grid2>
                                    <Grid2 container sx={{ color: grey }}>{getRequestConso(p).lowWaterVolume}</Grid2>
                                    <Grid2 container size={12}>{i18n.horsEtiage}</Grid2>
                                    <Grid2 container sx={{ color: grey }}>{getRequestConso(p).total - getRequestConso(p).lowWaterVolume}</Grid2>
                                </Grid2>
                            ) },
                            [i18n.advancement]: { value: '' },
                        }]
                    }
                )
            })
        )
    }

    const formatInstallationDeclaration = (instal) => (
        [
            ...declaration.link_declarationInstallation,
            {
                ...instal,
                stateCode: POINT_STATUS_DECLARATION.USED,
                idSurvey: declaration.idSurvey,
                idExploitation: exploitation.idExploitation,
                idInstallation: instal.id,
            }
        ]
    )

    return (
        <Grid2 container sx={{ height: 'auto', rowGap: '2vh' }}>
            {editMode &&
                <Grid2
                    sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.15px',
                        color: mainColor
                    }}
                >
                    {i18n.pleaseCheckGivenInfosForEachPoints}
                </Grid2>
            }
            <Grid2
                container
                size={12}
                alignItems='center'
                justifyContent='center'
            >
                {editMode && points.some(p => p.status !== POINT_ADVANCEMENT_DECLARATION.VALIDATED) &&
                    <MainButton
                        noFullWidth
                        reverse
                        sx={{
                            width: 'auto'
                        }}
                        onClick={() => {
                            const point = points.find(p => p.status !== POINT_ADVANCEMENT_DECLARATION.VALIDATED) || {}
                            if (!declaration.link_declarationInstallation.some(i => i.idInstallation === point.id)) {
                                dispatch(DeclarationAction.updateDeclaration(
                                    {
                                        ...declaration,
                                        link_declarationInstallation: formatInstallationDeclaration(point)
                                    }
                                ))
                            }
                            dispatch(push(`/declaration/point/${point.id}`))
                        }}
                    >
                        {i18n.startVolumeRequest}
                    </MainButton>
                }
            </Grid2>
            <Table
                rowClickable={editMode}
                accordion
                inverted
                grouped

                rows={getRows()}
                headers={[
                    [
                        { value: i18n.pointNameID, colSpan: 1, group: 1 },
                        { value: i18n.status, colSpan: 1, group: 2 },
                        { value: i18n.pumps, colSpan: 1, group: 3 },
                        { value: `${i18n.currentUsages} ${survey.surveyType === SURVEY_TYPE.ANNUAL_SURVEY ? survey.year - 1 : survey.year}`, colSpan: 2, group: 4 },
                        { value: `${i18n.askedsLabel} ${survey.year}`, colSpan: 2, group: 5 },
                        { value: i18n.advancement, colSpan: 1, group: 6 },
                    ],
                    [
                        { colSpan: 1, group: 1 },
                        { colSpan: 1, group: 2 },
                        { colSpan: 1, group: 3 },
                        { value: i18n.usesNumber, colSpan: 1, group: 4 },
                        { value: i18n.m3, colSpan: 1, group: 4 },
                        { value: i18n.usesNumber, colSpan: 1, group: 5 },
                        { value: i18n.m3, colSpan: 1, group: 5 },
                        { colSpan: 1, group: 6 },
                    ],
                ]}
                headersLabel={[i18n.pointNameID, i18n.status, i18n.pumps, `${i18n.usesNumber} ${i18n.currentUsages}`,
                    `${i18n.m3} ${i18n.currentUsages}`, `${i18n.usesNumber} ${i18n.askedsLabel}`, `${i18n.m3} ${i18n.askedsLabel}`,
                    i18n.advancement]}

                onClickRow={(row) => {
                    if (!declaration.link_declarationInstallation.some(i => i.idInstallation === row.onClickValue.id)) {
                        dispatch(DeclarationAction.updateDeclaration(
                            {
                                ...declaration,
                                link_declarationInstallation: formatInstallationDeclaration(row.onClickValue)
                            }
                        ))
                    }
                    dispatch(push(`/declaration/point/${row.onClickValue.id}`))
                }}
            />
            <Grid2 container size={12} justifyContent='center'>
                {
                    editMode ?
                        <MainButton noFullWidth sx={{ width: 'auto', margin: 0 }} onClick={
                            filteredPointsAdvancement.some(a =>
                                a.status === POINT_ADVANCEMENT_DECLARATION.ONGOING || a.status === POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
                            ) ?
                                () => {
                                    setOpenModal(true)
                                }
                                :
                                () => {
                                    setEditMode(false)
                                    dispatch(DeclarationAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.COMMENTS_STEP, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                                        dispatch(DeclarationAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
                                    })
                                }
                        }
                        >
                            {i18n.validConsosAndAsks}
                        </MainButton>
                        :
                        (declaration.statusCode !== DECLARATION_STATUS.SENT &&
                            <MainButton noFullWidth sx={{ width: 'auto', margin: 0 }} onClick={ () => {
                                setEditMode(true)
                                dispatch(DeclarationAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.POINTS_STEP, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                                    dispatch(DeclarationAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                                        setValidationStep(false)
                                    })
                                })
                            }}
                            >
                                {i18n.changeConsosAndAsks}
                            </MainButton>
                        )
                }
            </Grid2>
            {
                openModal &&
                <DialogMUI
                    open={openModal}
                >
                    <DialogTitleMUIv2 onClick={() => setOpenModal(false)}>
                        {i18n.missingInfos}
                    </DialogTitleMUIv2>
                    <DialogContentMUIDesktop>
                        {
                            orderBy(
                                filteredPointsAdvancement
                                    .filter(a => a.status !== POINT_ADVANCEMENT_DECLARATION.VALIDATED)
                                    .map(a => {
                                        const point = points.find(p => p.id === a.idPoint)
                                        return (
                                            <Grid2 container size={12} justifyContent='space-between' alignItems='center' sx={{ flexWrap: 'nowrap' }}>
                                                <Grid2 container sx={{ flexWrap: 'nowrap' }}>
                                                    <Grid2 sx={{ color: textColor }}><b>{point.code}</b> - {i18n.noDeclaration}</Grid2>
                                                </Grid2>
                                                <TextButton
                                                    onClick={() => {
                                                        if (!declaration.link_declarationInstallation.some(i => i.idInstallation === point.id)) {
                                                            dispatch(DeclarationAction.updateDeclaration(
                                                                {
                                                                    ...declaration,
                                                                    link_declarationInstallation: formatInstallationDeclaration(point)
                                                                }
                                                            ))
                                                        }
                                                        dispatch(push(`/declaration/point/${point.id}`))
                                                    }}
                                                    noFullWidth
                                                    sx={{ width: 'auto', padding: '1vh 1.2vw' }}
                                                    startIcon={<Add />}
                                                >
                                                    {i18n.add}
                                                </TextButton>
                                            </Grid2>
                                        )
                                    })
                                , ['status']
                            )
                        }
                        <Grid2 container size={12} justifyContent='center' sx={{ columnGap: '1vw' }}>
                            <MainButton
                                reverse
                                noFullWidth
                                sx={{ width: 'auto', margin: 0 }}
                                onClick={
                                    () => setOpenModal(false)
                                }
                            >
                                {i18n.comeBackToMyDeclaration}
                            </MainButton>
                            <MainButton
                                noFullWidth
                                sx={{ width: 'auto', margin: 0 }}
                                onClick={
                                    () => {
                                        setEditMode(false)
                                        setOpenModal(false)
                                        dispatch(DeclarationAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.COMMENTS_STEP }))
                                    }
                                }
                            >
                                {i18n.validConsosAndAsks}
                            </MainButton>
                        </Grid2>
                    </DialogContentMUIDesktop>
                </DialogMUI>
            }
        </Grid2>
    )
}

PointsValidation.propTypes = {
    setValidationStep: PropTypes.func
}

export default PointsValidation